import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col } from 'reactstrap'
import Layout from "../components/layout"
import SEO from "../components/seo"
import moment from 'moment'

const VISIBLE_COUNT = 10

class SermonSinglePage extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      visibleVideos: VISIBLE_COUNT
    }
  }

  viewMoreVideos = () => {
    this.setState({
      visibleVideos: this.state.visibleVideos + VISIBLE_COUNT
    })
  }

  render () {
    const video = this.props.pageContext.video
    const nextVideo = this.props.pageContext.next
    const previousVideo = this.props.pageContext.previous
    return (
      <Layout>
        <SEO title={video.title} />
        <Container>
        <section style={{height: 130, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <h1>{video.title}</h1>
        </section>
        <Row>
          <Col md={{size: 8, offset: 2}}>
            <iframe title={video.title} width="100%" height="500" src={`https://www.youtube.com/embed/${video.videoId}`} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />
            <p className='sermon-single-meta'>Dr Jay Jailal - {moment(video.publishedAt).format('MMMM DD, YYYY')}</p>
            <p className='sermon-single-description'>{video.description}</p>
           
          </Col>
        </Row>
        <Row>
          <Col>
            <div style={{height: 100, display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
              {previousVideo ? <p>Previous Video: <Link to={`/sermon/${previousVideo.videoId}`}>{previousVideo.title}</Link></p> : <div />}
              {nextVideo ? <p>Next Video: <Link to={`/sermon/${nextVideo.videoId}`}>{nextVideo.title}</Link></p> : <div />}
            </div>
            <div style={{height: 50}} />
          </Col>
        </Row>
        </Container>
      </Layout>
    )
  }
} 


export default SermonSinglePage
